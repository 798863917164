import { useState } from "react";
import Modal from "./Modal";
import Backdrop from "./Backdrop";

import classes from "./Card.module.css";

function Card(props) {
  const [modalDisplay, setModalDisplay] = useState(false);

  function deleteHandler() {
    setModalDisplay(true);
  }

  function closeModalHandler() {
    setModalDisplay(false);
  }

  return (
    <div className={classes.Card}>
      <h2>{props.text}</h2>
      {props.children}
      <div className="actions">
        <button className="btn" onClick={deleteHandler}>
          Delete
        </button>
      </div>
      {modalDisplay && (
        <Modal onCancel={closeModalHandler} onConfirm={closeModalHandler} />
      )}
      {modalDisplay && <Backdrop onClick={closeModalHandler} />}
    </div>
  );
}

export default Card;
